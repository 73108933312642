<template>
  <div class="salary-report">
    <KTCodePreview v-bind:title="'Báo cáo bảng lương'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="2">
            <date-picker
              placeholder="Bảng lương tháng"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="apiParam.month"
            ></date-picker>
          </b-col>
          <b-col>
            <b-button variant="primary" size="sm" @click="getReportByMonth">
              <i style="font-size: 1rem" class="fas fa-search"></i>Tìm kiếm
            </b-button>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <b-row class="mb-2" v-if="salaryReportSummary.id">
      <b-col cols="6">
        <b-card header-tag="header">
          <template #header class="m-2">
            <div class="d-flex d-inline">
              <h5 class="w-100 mb-0 mr-2">
                Bảng lương tháng:
                {{ formatDate(salaryReportSummary.salaryMonth, 'MM-YYYY') }}
              </h5>
              <b-badge
                class="w-50 justify-content-end"
                :variant="statusLableVariant"
              >
                {{ salaryReportSummary.statusName }}</b-badge
              >
            </div>
          </template>
          <b-card-text v-if="salaryReportSummary.status !== 0">
            <span class="mb-2 d-block">
              <strong>Người tạo</strong>:
              {{ salaryReportSummary.createdByName }}
            </span>
            <span class="mb-2 d-block">
              <strong>Thời gian tạo</strong>:
              {{ salaryReportSummary.createdAt }}
            </span>
            <b-form-group :disabled="true">
              <strong>Tiến trình:</strong>
              <b-form-checkbox-group
                v-model="salaryReportSummary.stepProcessed"
                :options="optionProcess"
                name="flavour-1a"
                size="lg"
              ></b-form-checkbox-group>
            </b-form-group>
            <b-row class="mb-4">
              <b-col md="4">
                <b-button
                  size="sm"
                  class="mb-2"
                  variant="success"
                  @click="runUpdateData('allowance')"
                  :disabled="isDisableSalaryAction ||
                    salaryReportSummary.stepProcessed.includes('allowance')"
                >
                  <b-icon
                    icon="arrow-down-circle-fill"
                    aria-hidden="true"
                  ></b-icon>
                  Tính phụ cấp
                </b-button>
              </b-col>
              <b-col md="4" class="text-center">
                <b-button
                  size="sm"
                  class="mb-2"
                  variant="info"
                  @click="runUpdateData('incurred')"
                  :disabled="
                    isDisableSalaryAction ||
                    salaryReportSummary.stepProcessed.includes('incurred')
                  "
                >
                  <b-icon
                    icon="arrow-down-circle-fill"
                    aria-hidden="true"
                  ></b-icon>
                  Tính phát sinh
                </b-button>
              </b-col>
              <b-col md="4" class="text-right">
                <b-button
                  size="sm"
                  class="mb-2"
                  variant="warning"
                  @click="runUpdateData('tax')"
                  :disabled="isDisableSalaryAction"
                >
                  <b-icon
                    icon="arrow-down-circle-fill"
                    aria-hidden="true"
                  ></b-icon>
                  Tính thuế TNCN
                </b-button>
              </b-col>
            </b-row>
            <b-progress
              :value="salaryReportSummary.stepProcessed.length"
              :max="3"
              show-progress
              animated
              class="mb-4"
            ></b-progress>
            <b-row>
              <b-col>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="reportClick()"
                  class="mr-4"
                >
                  <b-icon
                    icon="file-earmark-arrow-down-fill"
                    aria-hidden="true"
                  ></b-icon>
                  Xuất báo cáo
                </b-button>
                <b-button
                  size="sm"
                  variant="warning"
                  @click="showWarningAlert()"
                  v-if="isShowFinalizeSalary"
                  class="mr-4"
                >
                  <b-icon icon="check-circle-fill" aria-hidden="true"></b-icon>
                  Chốt bảng lương
                </b-button>
                <b-button size="sm" variant="danger" @click="showCancelAlert()">
                  <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon>
                  Hủy bảng lương
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
          <div v-else>
            <b-button variant="warning" size="sm" @click="runReport()"
              ><b-icon icon="lightning-fill" aria-hidden="true"></b-icon>Chạy
              báo cáo</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { formatDate, makeToastFaile, convertPrice } from '@/utils/common';
import { BASE_URL } from '@/utils/constants';
import { SALARY_REPORT_STATUS } from '@/utils/enum';
import axios from 'axios';
import fileDownload from '@/utils/file-download';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      apiParam: {
        month: '',
      },
      dpConfigs: {
        date: {
          format: 'MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      fields: [
        {
          key: 'employeeCode',
          label: 'MSNV',
          thStyle: {
            width: '5%',
          },
        },
        {
          key: 'fullName',
          label: 'Họ tên NV',
          thStyle: {
            width: '10%',
          },
        },
        {
          key: 'position',
          label: 'Vị trí',
          thStyle: {
            width: '10%',
          },
        },
        {
          key: 'positionSalary',
          label: 'Lương vị trí',
          thStyle: {
            width: '10%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.convertPrice(value);
          },
        },
        {
          key: 'transportationAllowance',
          label: 'Phụ cấp giữ xe',
          thStyle: {
            width: '10%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.convertPrice(value);
          },
        },
        {
          key: 'totalSalary',
          label: 'Tổng lương',
          tdClass: 'text-right',
          thStyle: {
            width: '10%',
          },
          formatter: (value) => {
            return this.convertPrice(value);
          },
        },
        {
          key: 'selfAllowance',
          label: 'Giảm trừ bản thân',
          tdClass: 'text-right',
          thStyle: {
            width: '10%',
          },
          formatter: (value) => {
            return this.convertPrice(value);
          },
        },
        {
          key: 'shuInsuranceDeduction',
          label: 'Khấu trừ BHXH',
          tdClass: 'text-right',
          thStyle: {
            width: '10%',
          },
          formatter: (value) => {
            return this.convertPrice(value);
          },
        },
        {
          key: 'incomeTax',
          label: 'Thu nhập tính thuế',
          tdClass: 'text-right',
          thStyle: {
            width: '10%',
          },
          formatter: (value) => {
            return this.convertPrice(value);
          },
        },
        {
          key: 'taxValue',
          label: 'Thuế TNCN',
          tdClass: 'text-right',
          thStyle: {
            width: '10%',
          },
          formatter: (value) => {
            return this.convertPrice(value);
          },
        },
      ],
      salaryReportSummary: {
        id: null,
        salaryMonth: '',
        status: 0,
        statusName: '',
        stepProcessed: [],
        createdByName: '',
      },
      optionProcess: [
        { text: 'Tính phụ cấp', value: 'allowance' },
        { text: 'Tính phí phát sinh', value: 'incurred' },
        { text: 'Tính thuế TNCN', value: 'tax' },
      ],
    };
  },
  computed: {
    isDisableSalaryAction() {
      return this.salaryReportSummary.status === SALARY_REPORT_STATUS.DONE;
    },
    statusLableVariant() {
      if (this.salaryReportSummary.status === SALARY_REPORT_STATUS.NEW) {
        return 'info';
      }
      if (this.salaryReportSummary.status === SALARY_REPORT_STATUS.PROCESSING) {
        return 'danger';
      }
      if (this.salaryReportSummary.status === SALARY_REPORT_STATUS.DONE) {
        return 'success';
      }
      return 'primary';
    },
    isShowFinalizeSalary() {
      return (
        this.salaryReportSummary.stepProcessed.length ===
        SALARY_REPORT_STATUS.DONE
      );
    },
  },
  methods: {
    convertPrice,
    formatDate,
    reportClick: async function () {
      if (!this.apiParam.month) {
        return makeToastFaile('Vui lòng chọn tháng lương!');
      }
      const month = moment(this.apiParam.month, 'MM/yyyy').toDate();
      const url = `${BASE_URL}salary/excel?month=${formatDate(
        month,
        'YYYY-MM-DD',
      )}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    runReport: async function () {
      if (!this.apiParam.month) {
        return makeToastFaile('Vui lòng chọn tháng lương!');
      }
      const month = moment(this.apiParam.month, 'MM/yyyy').toDate();
      const url = `${BASE_URL}salary/by-month/${formatDate(
        month,
        'YYYY-MM-DD',
      )}`;
      ApiService.post(url)
        .then((response) => {
          this.salaryReportSummary.id = response.data.data.id;
          this.salaryReportSummary.status = response.data.data.status;
          this.salaryReportSummary.statusName = response.data.data.statusName;
          this.salaryReportSummary.stepProcessed =
            response.data.data.stepProcessed;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    getReportByMonth: async function () {
      if (!this.apiParam.month) {
        return makeToastFaile('Vui lòng chọn tháng lương!');
      }
      const month = moment(this.apiParam.month, 'MM/yyyy').toDate();
      const url = `${BASE_URL}salary/by-month/${formatDate(
        month,
        'YYYY-MM-DD',
      )}`;
      ApiService.get(url)
        .then((response) => {
          this.salaryReportSummary = response.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    runUpdateData: async function (mode) {
      if (!this.apiParam.month) {
        return makeToastFaile('Vui lòng chọn tháng lương!');
      }
      const payload = {
        id: this.salaryReportSummary.id,
        mode,
      };
      ApiService.put('salary/by-month', payload)
        .then((response) => {
          this.salaryReportSummary.status = response.data.data.status;
          this.salaryReportSummary.statusName = response.data.data.statusName;
          this.salaryReportSummary.stepProcessed =
            response.data.data.stepProcessed;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    showWarningAlert: function () {
      Swal.fire({
        title: `Chốt bảng lương tháng ${this.salaryReportSummary.salaryMonth}!`,
        text: 'Bạn có chắc muốn chốt bảng lương này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Chốt',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.runUpdateData('finalize');
        }
      });
    },
    showCancelAlert: function () {
      Swal.fire({
        title: `Hủy bảng lương tháng ${this.salaryReportSummary.salaryMonth}!`,
        text: 'Bạn có chắc muốn hủy bảng lương này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.cancelSalaryReport();
        }
      });
    },
    cancelSalaryReport: async function () {
      if (!this.apiParam.month) {
        return makeToastFaile('Vui lòng chọn tháng lương!');
      }
      ApiService.put(`salary/cancle/${this.salaryReportSummary.id}`)
        .then((response) => {
          this.salaryReportSummary.status = response.data.data.status;
          this.salaryReportSummary.statusName = response.data.data.statusName;
          this.salaryReportSummary.stepProcessed =
            response.data.data.stepProcessed;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo hoa hồng chi tiết', route: 'detail' },
      { title: 'Danh sách báo cáo hoa hồng chi tiết' },
    ]);
  },
  created() {},
  components: {
    KTCodePreview,
  },
};
</script>

<style lang="scss">
.salary-report {
  table {
    td {
      vertical-align: middle !important;
    }
  }
}
</style>
